import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, DatePicker,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate, dateFormat, validateAmount,
} from '../../helper';
import { onNewPromotion } from '../saga';

const { RangePicker } = DatePicker;
const { Item } = Form;
const { Option } = Select;

const Create = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const createData = useCallback((params) => dispatch(
    onNewPromotion(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    if (values.dateRange.length > 0) {
      values.start_date = values.dateRange[0].format(dateFormat);
      values.end_date = values.dateRange[1].format(dateFormat);
    }

    delete values.dateRange;

    createData(values);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      title='Create Promotion'
    >
      <Form
        layout='vertical'
        form={form}
        name='createPromotion'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              name='code'
              label='Code'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Code' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='amount'
              label='Amount'
              rules={[
                fieldValidate.required,
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    return validateAmount(value, getFieldValue('promo_type'));
                  },
                }),
              ]}
            >
              <Input placeholder='Amount' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                fieldValidate.required]}
              name='promo_type'
              label='Promo Type'
            >
              <Select placeholder='Select a option'>
                <Option value='percentage'>Percentage</Option>
                <Option value='amount'>Amount</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[fieldValidate.required]}
              name='dateRange'
              label='Time:'
            >
              <RangePicker format={dateFormat} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Save
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Close
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
