import React, { useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col, Typography, Input, Form, Select, Button, InputNumber, DatePicker } from 'antd';
import UploadAvatar from '../../../shared/UploadAvatar';
import Content from '../Content';
import Tags from '../Content/Tags';
import { fieldValidate, dateFormat } from '../../helper';
import { onCreateBlog } from '../saga';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const NewBlog = () => {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const valuesRef = useRef({ content: {} });
  const history = useHistory();

  const createBlog = useCallback(
    (params) =>
      dispatch(
        onCreateBlog(params, (status, response) => {
          if (status === 200) {
            history.push({ pathname: `/blog/edit/${response.id}` });
          }
          setSubmitting(false);
        })
      ),
    [dispatch]
  );

  const onFinish = (values) => {
    valuesRef.current = _.merge(valuesRef.current, values);

    setSubmitting(true);
    createBlog(valuesRef.current);
  };

  return (
    <Row>
      <Col span={24} style={{ textAlign: 'center', marginBottom: 20 }}>
        <Title level={2}>Create Blog</Title>
      </Col>
      <Col span={20} offset={2}>
        <Form form={form} onFinish={onFinish} style={{ width: '100%' }} layout="vertical">
          <Row gutter={48}>
            <Col offset={4} span={16}>
              <Row>
                <Col span={24}>
                  <Form.Item name="title" label="Title" rules={[fieldValidate.required]}>
                    <Input placeholder="Title" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="metaDescription" label="Meta Description" rules={[fieldValidate.required]}>
                    <Input.TextArea placeholder="Meta Description" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="ordering" label="Position">
                    <InputNumber placeholder="Position" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="readTimes" label="Read time">
                    <InputNumber placeholder="Read time" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="createdAt" label="Published At">
                    <DatePicker format={dateFormat} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="featuredPost" label="Featured Post Text">
                    <Input placeholder="Featured Post Text" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="image" label="Main Image" rules={[fieldValidate.required]}>
                    <UploadAvatar onFileChange={(file) => form.setFieldsValue({ image: file })} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="mobileImage"
                    label="Mobile Image (Ratio: 4:3)"
                    rules={[fieldValidate.required]}
                  >
                    <UploadAvatar
                      imageStyle={{ width: 400, height: 300 }}
                      onFileChange={(file) => form.setFieldsValue({ mobileImage: file })}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="mainImageCredit" label="Main Image Credit">
                    <Input placeholder="Main Image Credit" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="status" label="Status" rules={[fieldValidate.required]}>
                    <Select placeholder="Select a option">
                      <Option value="publish">Publish</Option>
                      <Option value="unpublish">Unpublish</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="category" label="Category" rules={[fieldValidate.required]}>
                    <Select placeholder="Select a option">
                      <Option value="articles">Articles</Option>
                      <Option value="design">Design</Option>
                      <Option value="interviews">Interviews</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="profileImage" label="Profile Image" rules={[fieldValidate.required]}>
                    <UploadAvatar
                      circle
                      onFileChange={(file) => form.setFieldsValue({ profileImage: file })}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="profileName" label="Profile Name" rules={[fieldValidate.required]}>
                    <Input placeholder="Profile Name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="profilePosition" label="Profile Position" rules={[fieldValidate.required]}>
                    <Input placeholder="Profile Position" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="shortDescription" label="Short Description">
                    <TextArea placeholder="Short Description" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col offset={4} span={16}>
              <Content values={valuesRef} />
              <Row>
                <Col span={24}>
                  <Form.Item label="Tags">
                    <Tags values={valuesRef} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Form.Item style={{ justifyContent: 'center', textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" size="large" disabled={submitting}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default NewBlog;
