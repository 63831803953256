import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from '../App';
const dotenv = require('dotenv');

dotenv.config({ path: '../../../.env', silent: true });

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<App />, document.getElementById('root'));
});

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    // if (error.response.status === 500) {
    //   window.location.replace('/error/500');
    // }
    return Promise.reject(error);
  }
);
