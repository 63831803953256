import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { StyledUpload } from './styles';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const UploadImage = ({
  onFileChange, defaultValue, circle, crop = false, aspect, imageStyle
}) => {
  const [state, setState] = useState({ loading: false, imageUrl: defaultValue || '' });
  const { imageUrl, loading } = state;

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => setState({
        imageUrl: url,
        loading: false,
      }));

      if (onFileChange) onFileChange(info.file.originFileObj);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className='ant-upload-text'>Upload</div>
    </div>
  );

  useEffect(() => {
    setState({ loading: false, imageUrl: defaultValue });
  }, [defaultValue]);

  if (!crop) {
    return (
      <StyledUpload
        circle={circle}
        action='/api/v1/upload'
        name='avatar'
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? <img className='upload-image' src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
      </StyledUpload>
    );
  }

  return (
    <ImgCrop rotate aspect={aspect || 1}>
      <StyledUpload
        imageStyle={imageStyle}
        circle={circle}
        action='/api/v1/upload'
        name='avatar'
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? <img className='upload-image' src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
      </StyledUpload>
    </ImgCrop>
  );
};

export default UploadImage;
