import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import {
  Row, Col, Input, Form, Select, Button,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';

const { Option } = Select;

const Filter = ({ currentFilter, changeLocation }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  let typing = null;

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.setFieldsValue({
      keyword: '',
      promoTypeEq: ''
    });
    changeLocation();
  };

  useEffect(() => {
    const {
      keyword, promoTypeEq
    } = queryString.parse(location.search);

    form.setFieldsValue({
      keyword: keyword || '',
      promoTypeEq: promoTypeEq || '',
    });
  }, []);

  return (
    <Form layout='vertical' form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={6} xxl={6}>
          <Form.Item
            name='keyword'
            label='Search Keyword'
          >
            <Input placeholder='input keyword...' onChange={onSearch}/>
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={6} xxl={6}>
          <Form.Item
            name='promoTypeEq'
            label='Promo type'
          >
            <Select
              placeholder='Select a option'
              onChange={(e) => onFilter('promoTypeEq', e)}
            >
              <Option value=''>All</Option>
              <Option value='percentage'>Percentage</Option>
              <Option value='amount'>Amount</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={6}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type='primary' onClick={clearFilter} icon={<FilterOutlined />}>Clear Filter</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
