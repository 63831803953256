import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -55px;
  margin-bottom: 30px;

  .ant-upload-picture-card-wrapper {
    display: flex;
    flex-flow: wrap row;
  }

  .avatar-uploader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .ant-upload-list-item {
    width: 160px !important;
    height: 160px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .ant-upload-list-picture-card {
    display: flex;
    flex-flow: wrap row;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 160px !important;
    height: 160px !important;
    margin-bottom: 0 !important;
    margin-top: 10px !important;
  }

  ${({ totalItem, disabled, limitFile }) => (totalItem === limitFile || disabled) && css`
    .ant-upload-select {
      display: none !important;
    }
  `}
`;

export default Wrapper;
