import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import StyledResult from './styles';

const Error = ({ status, title, subTitle }) => {
  const history = useHistory();
  const redirectToHome = () => history.push('/blogs');

  return (
    <StyledResult
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <Button type='primary' onClick={redirectToHome}>Back Home</Button>
      }
    />
  );
};

Error.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

export default Error;
