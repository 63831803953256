import axios, { post, get, put as axiosPut, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../shared/Notification';
import { getErrorMessage } from '../../shared/const';
import { history } from '../../vendor/history';

const ON_GET_BLOGS = 'ON_GET_BLOGS';
const ON_CREATE_BLOG = 'ON_CREATE_BLOG';
const ON_EDIT_BLOG = 'ON_EDIT_BLOG';
const ON_GET_BLOG_DETAIL = 'ON_GET_BLOG_DETAIL';
const ON_DELETE_BLOG = 'ON_DELETE_BLOG';

const request = (params) =>
  axios({
    method: 'GET',
    url: '/api/v1/blogs',
    params
  });

function* getBlogs({ params, setData }) {
  try {
    const response = yield call(request, params);
    setData({
      loading: false,
      items: response.data.items,
      totalPage: response.data.totalPage,
      currentPage: response.data.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* deleteBlog({ id, callback }) {
  try {
    yield call(axiosDelete, `/api/v1/blogs/${id}`);

    Notification.success({ message: 'Blog has been deleted.' });
    callback();
  } catch (error) {
    console.log(error);
  }
}

function* createBlog({ params, setState }) {
  try {
    const formData = new FormData();
    formData.append('title', params.title);
    formData.append('featured_post', params.featuredPost || '');
    formData.append('short_description', params.shortDescription || '');
    formData.append('meta_description', params.metaDescription || '');
    formData.append('status', params.status);
    formData.append('category', params.category);
    formData.append('main_image_credit', params.mainImageCredit || '');
    formData.append('profile_name', params.profileName);
    formData.append('profile_position', params.profilePosition || '');
    formData.append('profile_image', params.profileImage);
    formData.append('image', params.image);
    formData.append('mobile_image', params.mobileImage);
    formData.append('content', JSON.stringify(params.content));
    formData.append('tags', params.tags);
    formData.append('created_at', params.createdAt);
    formData.append('read_times', params.readTimes || 0);
    formData.append('ordering', params.ordering || 0);

    const response = yield call(post, '/api/v1/blogs', formData);

    if (response.code === 200) {
      Notification.success({ message: 'Blog has been created successfully.' });
      setState(200, response.data);
    } else {
      setState(400);
      Notification.error({ message: getErrorMessage(response.code) });
    }
  } catch (error) {
    console.log(error);
  }
}

function* editBlog({ params, setState }) {
  try {
    const formData = new FormData();
    formData.append('title', params.title);
    formData.append('featured_post', params.featuredPost || '');
    formData.append('short_description', params.shortDescription || '');
    formData.append('meta_description', params.metaDescription || '');
    formData.append('status', params.status);
    formData.append('category', params.category);
    formData.append('main_image_credit', params.mainImageCredit || '');
    formData.append('profile_name', params.profileName);
    formData.append('profile_position', params.profilePosition || '');
    formData.append('content', JSON.stringify(params.content));
    formData.append('tags', params.tags);
    formData.append('created_at', params.createdAt);
    formData.append('ordering', params.ordering || 0);
    formData.append('read_times', params.readTimes || 0);

    if (params.profileImage) formData.append('profile_image', params.profileImage);
    if (params.image) formData.append('image', params.image);
    if (params.mobileImage) formData.append('mobile_image', params.mobileImage);

    const response = yield call(axiosPut, `/api/v1/blogs/${params.id}`, formData);

    if (response.code === 200) {
      Notification.success({ message: 'Blog has been updated successfully.' });
      setState(200);
    } else {
      Notification.error({ message: getErrorMessage(response.code) });
      setState(400);
    }
  } catch (error) {
    console.log(error);
  }
}

function* getBlog({ id, setData }) {
  try {
    const response = yield call(get, `/api/v1/blogs/${id}/edit/`);

    if (response.code === 200) {
      setData({
        loading: false,
        item: response.data.item
      });
    } else {
      Notification.error({ message: 'Blogs not found.' });
      history.push('/blogs');
    }
  } catch (error) {
    console.log(error);
  }
}

export const onGetBlog = (id, setData) => ({
  type: ON_GET_BLOG_DETAIL,
  id,
  setData
});

export const onEditBlog = (params, setState) => ({
  type: ON_EDIT_BLOG,
  params,
  setState
});

export const onCreateBlog = (params, setState) => ({
  type: ON_CREATE_BLOG,
  params,
  setState
});

export const onGetBlogs = (params, setData) => ({
  type: ON_GET_BLOGS,
  params,
  setData
});

export const onDeleteBlog = (id, callback) => ({
  type: ON_DELETE_BLOG,
  id,
  callback
});

export default function* blogWatcher() {
  yield takeLatest(ON_DELETE_BLOG, deleteBlog);
  yield takeLatest(ON_GET_BLOGS, getBlogs);
  yield takeLatest(ON_CREATE_BLOG, createBlog);
  yield takeLatest(ON_EDIT_BLOG, editBlog);
  yield takeLatest(ON_GET_BLOG_DETAIL, getBlog);
}
