import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spin from './styles';
import { onAuthentication } from './redux';
import Layout from './Layout';

export default function PrivateRoute({ component: Component, ...rest }) {
  const authentication = useSelector((state) => state.privateRoute.authentication);
  const [status, setStatus] = useState(401);
  const dispatch = useDispatch();
  const history = useHistory();

  const userAuthentication = useCallback(
    () =>
      dispatch(
        onAuthentication((responseStatus) => {
          if (responseStatus === 200) {
            setStatus(200);
          } else {
            history.push('/login');
          }
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    if (!authentication) userAuthentication();
  }, []);

  if (status === 200) {
    return (
      <Layout>
        <Route {...rest} render={(props) => <Component match={props.match} />} />
      </Layout>
    );
  }

  return <Spin size="large" />;
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};
