export const getErrorMessage = (error) => {
  const { response } = error;
  switch (response.status) {
    case 422:
      if (response.data.errors) {
        return response.data.errors.join(', ') || 'System Error!!!';
      } if (response.data.code) {
        return 'Code is existed';
      }
    default:
      return 'System Error!!!';
  }
};

export default getErrorMessage;
