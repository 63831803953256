import React, {
  useState, useImperativeHandle,
} from 'react';
import {
  Row, Modal, Button, Descriptions, Typography, Col,
} from 'antd';
import Table from '../../../shared/Table';
import {
  numberToCurrency,
} from '../../helper';

const { Title } = Typography;

const columns = [
  {
    title: 'Image',
    dataIndex: 'image',
    render: (image) => <img src={image} style={{ height: 100 }} alt='error' />,
  },
  {
    title: 'Product',
    dataIndex: 'name',
  },
  {
    title: 'T-Shirt Color',
    dataIndex: 'color',
  },
  {
    title: 'Text Print Color',
    dataIndex: 'printColor',
  },
  {
    title: 'Size',
    dataIndex: 'size',
  },
  {
    title: 'Quantiy',
    dataIndex: 'quantity',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    render: (price) => numberToCurrency(price),
  },
  {
    title: 'TotalPrice',
    render: ({ price, quantity }) => numberToCurrency(price * quantity),
  },
];

const Preview = React.forwardRef(({}, ref) => {
  const [isShow, setShowModal] = useState(false);
  const [item, setItem] = useState({});

  const close = () => {
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setShowModal(true);
      setItem(item);
    },
  }));

  return (
    <Modal
      onCancel={close}
      closable={true}
      visible={isShow}
      footer={null}
      title={<Title level={4}>{`Transaction Id: ${item.transactionId || ''}`}</Title>}
      width={1280}
    >
      <Row gutter={48}>
        <Col span={24}>
          <Title level={4}>Basic Information</Title>
        </Col>
        {item.userInfo && (
          <Col span={24}>
            <Descriptions size='small' column={2}>
              <Descriptions.Item label='Email Address'>{item.userInfo.email}</Descriptions.Item>
              <Descriptions.Item label='Contact Number'>{item.userInfo.contactNumber}</Descriptions.Item>
            </Descriptions>
          </Col>
        )}
        <Col span={24} style={{ marginTop: 20 }}>
          <Title level={4}>Delivery Address</Title>
        </Col>
        {item.userInfo && (
          <Col span={24}>
            <Descriptions size='small' column={2}>
              <Descriptions.Item label='Name'>{item.userInfo.name}</Descriptions.Item>
              <Descriptions.Item label='Delivery Country'>{item.userInfo.country}</Descriptions.Item>
              <Descriptions.Item label='Address'>{item.userInfo.address}</Descriptions.Item>
              <Descriptions.Item label='Postcode'>{item.userInfo.postcode}</Descriptions.Item>
            </Descriptions>
          </Col>
        )}
        <Col span={24} style={{ marginTop: 20 }}>
          <Table
            title={`Total Price: ${numberToCurrency(item.amount) || 0}`}
            columns={columns}
            dataSource={item.orderProducts || []}
            currentPage={1}
            totalPages={1}
          />
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: 20 }}>
        <Button
          size='large'
          style={{ width: 150 }}
          onClick={close}
        >
          Close
        </Button>
      </Row>
    </Modal>
  );
});

export default Preview;
