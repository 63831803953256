import React, {
  useState, useImperativeHandle, useCallback, useRef, useEffect,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, Tooltip, Popconfirm,
} from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  fieldValidate, numberToCurrency, removeCurrency,
} from '../../helper';
import UploadList from '../../../shared/UploadList';
import {
  Items, HeaderWrapper, Name, Action, Item, Code, RowItem, ColorCode, ColorStatus, ColorName
} from './styles';
import { onEditProduct } from '../saga';

const { Option } = Select;

const Marterial = React.forwardRef(({
  name, prefix, defaultValues, form,
}, ref) => {
  const [stuffs, setStuffs] = useState(defaultValues);

  useImperativeHandle(ref, () => ({
    listItem: () => stuffs,
    resetStuff: () => setStuffs([]),
  }));

  const addStuff = () => {
    setStuffs(stuffs.concat([{}]));
  };

  const removeStuff = (index) => {
    const currentStuffs = stuffs;
    currentStuffs.splice(index, 1);
    setStuffs([].concat(currentStuffs));
  };

  useEffect(() => {
    setStuffs(defaultValues);
    const values = {};
    values[`${prefix}_size`] = defaultValues.map((item) => item.size);
    values[`${prefix}_shoulder`] = defaultValues.map((item) => item.shoulder);
    values[`${prefix}_chest`] = defaultValues.map((item) => item.chest);
    values[`${prefix}_sleeve`] = defaultValues.map((item) => item.sleeve);
    values[`${prefix}_length`] = defaultValues.map((item) => item.length);
    form.setFieldsValue(values);
  }, [defaultValues]);

  return (
    <>
      <Form.Item label={name} style={{ margin: 0 }} />
      <Items>
        <HeaderWrapper>
          <RowItem withBackground>Size</RowItem>
          <RowItem withBackground>Shoulder</RowItem>
          <RowItem withBackground>Chest</RowItem>
          <RowItem withBackground>Sleeve</RowItem>
          <RowItem withBackground>Length</RowItem>
          <Action withBackground />
        </HeaderWrapper>
        {
            stuffs.map((item, index) => (
              <Item key={index}>
                <RowItem>
                  <Form.Item
                    initialValue={item.size || ''}
                    name={[`${prefix}_size`, index]}
                    rules={[fieldValidate.required]}
                  >
                    <Input placeholder='Size' />
                  </Form.Item>
                </RowItem>
                <RowItem>
                  <Form.Item
                    initialValue={item.shoulder || ''}
                    name={[`${prefix}_shoulder`, index]}
                    rules={[fieldValidate.required]}
                  >
                    <Input placeholder='Shoulder' />
                  </Form.Item>
                </RowItem>
                <RowItem>
                  <Form.Item
                    initialValue={item.chest || ''}
                    name={[`${prefix}_chest`, index]}
                    rules={[fieldValidate.required]}
                  >
                    <Input placeholder='Chest' />
                  </Form.Item>
                </RowItem>
                <RowItem>
                  <Form.Item
                    initialValue={item.sleeve || ''}
                    name={[`${prefix}_sleeve`, index]}
                    rules={[fieldValidate.required]}
                  >
                    <Input placeholder='Sleeve' />
                  </Form.Item>
                </RowItem>
                <RowItem>
                  <Form.Item
                    initialValue={item.length || ''}
                    name={[`${prefix}_length`, index]}
                    rules={[fieldValidate.required]}
                  >
                    <Input placeholder='Length' />
                  </Form.Item>
                </RowItem>
                <Action>
                  <Tooltip title='Delete' color='red' key='red'>
                    <Popconfirm placement='top' title='Do you want to remove this record?' onConfirm={() => removeStuff(index)} okText='Yes' cancelText='No'>
                      <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                    </Popconfirm>
                  </Tooltip>
                </Action>
              </Item>
            ))
          }
        <Form.Item>
          <Button
            type='dashed'
            onClick={addStuff}
            block
          >
            <PlusOutlined />
            {' '}
            Add More
          </Button>
        </Form.Item>
      </Items>
    </>
  );
});

const ColorComponent = React.forwardRef(({
  name, prefix, defaultValues, form,
}, ref) => {
  const [stuffs, setStuffs] = useState(defaultValues);

  useImperativeHandle(ref, () => ({
    listItem: () => stuffs,
    resetStuff: () => setStuffs([]),
  }));

  const addStuff = () => {
    setStuffs(stuffs.concat([{ name: '', colorCode: '' }]));
  };

  const removeStuff = (index) => {
    const currentStuffs = stuffs;
    currentStuffs.splice(index, 1);
    setStuffs([].concat(currentStuffs));
  };

  useEffect(() => {
    setStuffs(defaultValues);
    const values = {};
    values[`${prefix}_name`] = defaultValues.map((item) => item.name);
    values[`${prefix}_code`] = defaultValues.map((item) => item.code);
    values[`${prefix}_status`] = defaultValues.map((item) => item.status);
    form.setFieldsValue(values);
  }, [defaultValues]);

  return (
    <>
      <Form.Item label={name} style={{ margin: 0 }} />
      <Items>
        <HeaderWrapper>
          <ColorName withBackground>Name</ColorName>
          <ColorCode withBackground>Color Code</ColorCode>
          <ColorStatus withBackground>Status</ColorStatus>
          <Action withBackground />
        </HeaderWrapper>
        {
            stuffs.map((item, index) => (
              <Item key={index}>
                <ColorName>
                  <Form.Item
                    initialValue={item.name || ''}
                    name={[`${prefix}_name`, index]}
                    rules={[fieldValidate.required]}
                  >
                    <Input placeholder='Name' />
                  </Form.Item>
                </ColorName>
                <ColorCode>
                  <Form.Item
                    initialValue={item.code || ''}
                    name={[`${prefix}_code`, index]}
                    rules={[fieldValidate.required]}
                  >
                    <Input placeholder='Code' />
                  </Form.Item>
                </ColorCode>
                <ColorStatus>
                  <Form.Item
                    initialValue={item.status || ''}
                    name={[`${prefix}_status`, index]}
                    rules={[fieldValidate.required]}
                  >
                    <Select
                      placeholder='Select a option'
                    >
                      <Option value='available'>Available</Option>
                      <Option value='sold_out'>Sold Out</Option>
                    </Select>
                  </Form.Item>
                </ColorStatus>
                <Action>
                  <Tooltip title='Delete' color='red' key='red'>
                    <Popconfirm placement='top' title='Do you want to remove this record?' onConfirm={() => removeStuff(index)} okText='Yes' cancelText='No'>
                      <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                    </Popconfirm>
                  </Tooltip>
                </Action>
              </Item>
            ))
          }
        <Form.Item>
          <Button
            type='dashed'
            onClick={addStuff}
            block
          >
            <PlusOutlined />
            {' '}
            Add More
          </Button>
        </Form.Item>
      </Items>
    </>
  );
});

const SizeComponent = React.forwardRef(({
  form, name, prefix, defaultValues,
}, ref) => {
  const [stuffs, setStuffs] = useState(defaultValues);

  useImperativeHandle(ref, () => ({
    listItem: () => stuffs,
    resetStuff: () => setStuffs([]),
  }));

  const addStuff = () => {
    setStuffs(stuffs.concat([{ name: '', colorCode: '' }]));
  };

  const removeStuff = (index) => {
    const currentStuffs = stuffs;
    currentStuffs.splice(index, 1);
    setStuffs([].concat(currentStuffs));
  };

  useEffect(() => {
    setStuffs(defaultValues);
    const values = {};
    values[`${prefix}_name`] = defaultValues.map((item) => item.name);
    values[`${prefix}_status`] = defaultValues.map((item) => item.status);
    form.setFieldsValue(values);
  }, [defaultValues]);

  return (
    <>
      <Form.Item label={name} style={{ margin: 0 }} />
      <Items>
        <HeaderWrapper>
          <Name withBackground>Name</Name>
          <Code withBackground>Status</Code>
          <Action withBackground />
        </HeaderWrapper>
        {
          stuffs.map((item, index) => (
            <Item key={index}>
              <Name>
                <Form.Item
                  initialValue={item.name || ''}
                  name={[`${prefix}_name`, index]}
                  rules={[fieldValidate.required]}
                >
                  <Input placeholder='Name' />
                </Form.Item>
              </Name>
              <Code>
                <Form.Item
                  initialValue={item.status || ''}
                  name={[`${prefix}_status`, index]}
                  rules={[fieldValidate.required]}
                >
                  <Select
                    placeholder='Select a option'
                  >
                    <Option value='available'>Available</Option>
                    <Option value='sold_out'>Sold Out</Option>
                  </Select>
                </Form.Item>
              </Code>
              <Action>
                <Tooltip title='Delete' color='red' key='red'>
                  <Popconfirm placement='top' title='Do you want to remove this record?' onConfirm={() => removeStuff(index)} okText='Yes' cancelText='No'>
                    <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                  </Popconfirm>
                </Tooltip>
              </Action>
            </Item>
          ))
        }
        <Form.Item>
          <Button
            type='dashed'
            onClick={addStuff}
            block
          >
            <PlusOutlined />
            {' '}
            Add More
          </Button>
        </Form.Item>
      </Items>
    </>
  );
});

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const uploadRef = useRef();
  const dispatch = useDispatch();
  const colorRef = useRef();
  const sizeRef = useRef();
  const printColorRef = useRef();
  const marterialRef = useRef();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    colorRef.current.resetStuff();
    sizeRef.current.resetStuff();
    marterialRef.current.resetStuff();
    printColorRef.current.resetStuff();
    uploadRef.current.reset();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditProduct(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);

    const formData = new FormData();
    formData.append('product[id]', item.id);
    formData.append('product[name]', values.name);
    formData.append('product[designer]', values.designer);
    formData.append('product[status]', values.status);
    formData.append('product[description]', values.description);
    formData.append('product[price]', removeCurrency(values.price));
    formData.append('product[material_short_description]', values.material_short_description);

    (values.colors_name || []).map((name, index) => {
      formData.append('product[colors][][name]', name);
      formData.append('product[colors][][code]', values.colors_code[index]);
      formData.append('product[colors][][status]', values.colors_status[index]);
    });

    (values.print_colors_name || []).map((name, index) => {
      formData.append('product[print_colors][][name]', name);
      formData.append('product[print_colors][][code]', values.print_colors_code[index]);
      formData.append('product[print_colors][][status]', values.print_colors_status[index]);
    });

    (values.sizes_name || []).map((name, index) => {
      formData.append('product[sizes][][name]', name);
      formData.append('product[sizes][][status]', values.sizes_status[index]);
    });

    (values.materials_size || []).map((size, index) => {
      formData.append('product[material_descriptions][][size]', size);
      formData.append('product[material_descriptions][][shoulder]', values.materials_shoulder[index]);
      formData.append('product[material_descriptions][][chest]', values.materials_chest[index]);
      formData.append('product[material_descriptions][][sleeve]', values.materials_sleeve[index]);
      formData.append('product[material_descriptions][][length]', values.materials_length[index]);
    });

    if (values.images) {
      values.images.forEach((file) => {
        formData.append('product[images][]', file.id ? file.id : file);
      });
    }

    editData({ id: item.id, formData });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        name: item.name,
        designer: item.designer,
        status: item.status,
        price: numberToCurrency(item.price),
        description: item.description,
        material_short_description: item.materialShortDescription,
        images: item.images,
      });
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      width={1140}
      title={`Edit Product: ${item.name || ''}`}
    >
      <Form
        layout='vertical'
        form={form}
        name='editProduct'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name='designer'
              label='Apparel'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Apparel' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='name'
              label='Name'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Name' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[fieldValidate.required]}
              name='status'
              label='Status'
            >
              <Select placeholder='Select a option'>
                <Option value='publish'>Publish</Option>
                <Option value='unpublish'>Unpublish</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='price'
              label='Price'
              rules={[
                fieldValidate.required,
              ]}
              onChange={(e) => e.target.value && form.setFieldsValue({
                price: numberToCurrency(removeCurrency(e.target.value)),
              })}
            >
              <Input placeholder='Price' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='description'
              label='Description'
            >
              <Input.TextArea placeholder='Description' rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <ColorComponent ref={colorRef} prefix='colors' name='T-shirt Colors' defaultValues={item.colors || []} form={form} />
          </Col>
          <Col span={12}>
            <ColorComponent ref={printColorRef} prefix='print_colors' name='Print Colors' defaultValues={item.printColors || []} form={form} />
          </Col>
          <Col span={12}>
            <SizeComponent ref={sizeRef} prefix='sizes' name='Sizes' defaultValues={item.sizes || []} form={form} />
          </Col>
          <Col span={24}>
            <Form.Item
              name='material_short_description'
              label='Marterial Short Description'
            >
              <Input.TextArea placeholder='Marterial Short Description' rows={3} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Marterial ref={marterialRef} prefix='materials' name='Material Description' defaultValues={item.materialDescriptions || []} form={form} />
          </Col>
          <Col span={24}>
            <Form.Item
              label='Images'
              name='images'
              rules={[
                fieldValidate.required,
              ]}
            />
            <UploadList
              noCrop={false}
              limitFile={6}
              aspect={0.846153846}
              ref={uploadRef}
              defaultValue={item.images || []}
              circle
              onChange={(images) => form.setFieldsValue({ images })}
            />
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Save
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Close
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
