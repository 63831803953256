import { post } from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';

import { ON_AUTHENTICATION, onUpdateAuthtication } from './redux';

function* userAuthentication({ callback }) {
  try {
    const response = yield call(post, '/api/v1/authentication');

    if (response.code === 200) {
      yield put(onUpdateAuthtication(true));
    }

    callback(response.code);
  } catch (error) {
    callback(error.response.status);
  }
}

export default function* routeWatcher() {
  yield takeLatest(ON_AUTHENTICATION, userAuthentication);
}
