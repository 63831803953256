import React from 'react';
import { Select } from 'antd';

const SearchInput = ({ children,...rest }) => (
  <Select
    optionFilterProp='children'
    showSearch
    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    {...rest}
  >
    {children}
  </Select>
);

export default SearchInput;
