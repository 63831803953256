import React, {
  useCallback, useState,
} from 'react';
import {
  Input, Form, Button, Col, Row, Card,
} from 'antd';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  StyledCol,
  LogoWrapper,
  StyledRow,
  StyledForm,
  Logo,
} from './styles';
import { onLogin } from './saga';

const layout = {
  wrapperCol: { span: 24 },
};

const Login = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback((params) => dispatch(
    onLogin(params, (status) => {
      if (status === 200) history.push({ pathname: '/blogs' });
      setLoading(false);
    }),
  ), [dispatch]);

  const onFinish = (values) => {
    onSubmit(values);
    setLoading(true);
  };

  return (
    <StyledRow>
      <StyledCol xs={{ span: 24 }} md={{ span: 18, offset: 2 }} xl={{ span: 8, offset: 8 }}>
        <Card
          title={(
            <LogoWrapper>
              <Logo src='/images/logo.svg' />
            </LogoWrapper>
          )}
          bordered={false}
        >
          <StyledForm
            {...layout}
            form={form}
            name='login'
            onFinish={onFinish}
            scrollToFirstError
            layout='vertical'
          >
            <Row justify='center'>
              <Col span={24}>
                <Form.Item
                  name='email'
                  label='Email'
                  rules={[
                    { type: 'email', message: 'Invalid email' },
                    {
                      required: true,
                      message: 'Please input your email',
                    },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  className
                  name='password'
                  label='Password'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password placeholder='Password' />
                </Form.Item>
              </Col>
              <Form.Item
                shouldUpdate={true}
              >
                {() => (
                  <Button
                    shape='round'
                    size='large'
                    id='loginButton'
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                    disabled={
                      !form.isFieldsTouched(true)
                      || form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                  >
                    LOGIN
                  </Button>
                )}
              </Form.Item>
            </Row>
          </StyledForm>
        </Card>

      </StyledCol>
    </StyledRow>
  );
};

export default Login;
