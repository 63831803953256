export const fieldValidate = {
  required: {
    required: true,
    message: 'This field is required.',
  },
  email: {
    type: 'email',
    message: 'Invalid email',
  },
};

export const validateAmount = (values, promoType) => {
  if (values) {
    const parseValue = parseFloat(values);
    if (isNaN(parseValue)) return Promise.reject('Invalid amount');
    if (promoType === 'percentage' && (parseValue > 100 || parseValue < 0)) {
      return Promise.reject('Invalid amount');
    }
    if (promoType === 'amount' && parseValue < 0) {
      return Promise.reject('Invalid amount');
    }
  }
  return Promise.resolve();
};

export const numberToCurrency = (number) => `${parseInt(number, 10).toString().replace(/[,]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

export const removeCurrency = (number) => {
  const convertedNumber = parseInt(number.toString().replace(/[,]/g, ''), 10);

  if (Number.isInteger(convertedNumber)) return convertedNumber;
  return 0;
};


export const dateFormat = 'DD/MM/YYYY';