import { post } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../shared/Notification';
import { getErrorMessage } from '../../shared/const';

export const ON_LOGIN = 'ON_LOGIN';

function* authentication({ params, callback }) {
  try {
    const response = yield call(post, '/api/v1/login', params);

    if (response.code === 200) {
      Notification.success({ message: 'Logged Successfully' });
      callback(200);
    } else {
      callback(400);
      Notification.error({ message: getErrorMessage(response.code) });
    }
  } catch (error) {
    console.error(error);
  }
}

export const onLogin = (params, callback) => ({
  type: ON_LOGIN, params, callback,
});

export default function* loginWatcher() {
  yield takeLatest(ON_LOGIN, authentication);
}
