import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from '../config/store';
import { history } from '../vendor/history';

import Login from '../pages/Login';

import Blogs from '../pages/Blogs/List';
import NewBlog from '../pages/Blogs/New';
import EditBlog from '../pages/Blogs/Edit';

import Promotions from '../pages/Promotions';
import Products from '../pages/Products';
import Orders from '../pages/Orders';

import Error from '../pages/Error';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import GlobalStyle from './styles';

const store = configureStore();

export default () => (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <PublicRoute exact path="/login" component={Login} />

        <PrivateRoute exact path="/blogs" component={Blogs} />
        <PrivateRoute exact path="/blog/new" component={NewBlog} />
        <PrivateRoute exact path="/blog/edit/:id" component={EditBlog} />
        <PrivateRoute exact path="/promotions" component={Promotions} />
        <PrivateRoute exact path="/products" component={Products} />
        <PrivateRoute exact path="/orders" component={Orders} />
        <Route
          exact
          path="/error/500"
          component={() => <Error status="500" title="500" subTitle="Sorry, something went wrong." />}
        />
        <Route
          component={() => (
            <Error status="404" title="404" subTitle="Sorry, the page you visited does not exist." />
          )}
        />
      </Switch>
      <GlobalStyle />
    </Router>
  </Provider>
);
