import React, { useState, useEffect } from 'react';
import { Tag, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const Tags = ({ values, defaultValue }) => {
  const [state, setState] = useState({
    tags: [],
    inputVisible: false,
    inputValue: '',
    editInputIndex: -1,
    editInputValue: '',
  });

  const handleClose = (removedTag) => {
    const tags = state.tags.filter((tag) => tag !== removedTag);
    setState({ ...state, ...{ tags } });
  };

  const showInput = () => {
    setState({ ...state, ...{ inputVisible: true } });
  };

  const handleInputChange = (e) => {
    setState({ ...state, ...{ inputValue: e.target.value } });
  };

  const handleInputConfirm = () => {
    const { inputValue } = state;
    let { tags } = state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }

    setState({
      ...state,
      ...{
        tags,
        inputVisible: false,
        inputValue: '',
      },
    });
  };

  const handleEditInputChange = (e) => {
    setState({ ...state, ...{ editInputValue: e.target.value } });
  };

  const handleEditInputConfirm = () => {
    setState(({ tags, editInputIndex, editInputValue }) => {
      const newTags = [...tags];
      newTags[editInputIndex] = editInputValue;

      return {
        ...state,
        ...{
          tags: newTags,
          editInputIndex: -1,
          editInputValue: '',
        },
      };
    });
  };

  const {
    tags, inputVisible, inputValue, editInputIndex, editInputValue,
  } = state;

  useEffect(() => {
    values.current.tags = tags;
  }, [tags]);

  useEffect(() => {
    if (!defaultValue) return;
    setState({ ...state, ...{ tags: defaultValue } });
    values.current.tags = defaultValue;
  }, [defaultValue]);

  return (
    <>
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              key={tag}
              size='small'
              className='tag-input'
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag
            className='edit-tag'
            key={tag}
            closable={true}
            onClose={() => handleClose(tag)}
          >
            <span
              onDoubleClick={(e) => {
                if (index !== 0) {
                  setState({ ...state, ...{ editInputIndex: index, editInputValue: tag } });
                  e.preventDefault();
                }
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
      <Input
        type='text'
        size='small'
        className='tag-input'
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
      />
      )}
      {!inputVisible && (
      <Tag className='site-tag-plus' onClick={showInput}>
        <PlusOutlined />
        {' '}
        New Tag
      </Tag>
      )}
    </>
  );
};

export default Tags;
