import styled, { css } from 'styled-components';
import { Upload } from 'antd';

export const StyledUpload = styled(Upload)`
  .ant-upload {
    display: flex !important;
    justify-content: center;
    align-items: center;
    ${({ circle }) => (circle ? css`
      width: 160px !important;
      height: 160px !important;
      border-radius: 100% !important;
    ` : css`
        width: 100% !important;
        height: 300px !important;
    `)}

    position: relative;
    overflow: hidden;



    .upload-image {
      object-fit: cover;
      ${({ circle }) => (circle ? css`
        width: 160px !important;
        height: 160px !important;
        border-radius: 100% !important;
      ` : css`
          width: 100% !important;
          height: 300px !important;
      `)}

      ${({ imageStyle }) => (imageStyle && css`
        width: ${imageStyle.width}px !important;
        height: ${imageStyle.height}px !important;`)
}
    }
  }
`;

export default StyledUpload;
