import {
  post, get, delete as axiosDelete, put as axiosPut,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../shared/Notification';
import { getErrorMessage } from '../../shared/const';

const ON_GET_PRODUCTS = 'ON_GET_PRODUCTS';
const ON_CREATE_PRODUCT = 'ON_CREATE_PRODUCT';
const ON_EDIT_PRODUCT = 'ON_EDIT_PRODUCT';

function* getProducts({ params, setData }) {
  try {
    const response = yield call(get, '/api/v1/products', { params });

    setData({
      loading: false,
      items: response.items,
      totalPage: response.totalPage,
      currentPage: response.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* createProduct({ params, callback }) {
  try {
    yield call(post, '/api/v1/products', params);

    Notification.success({ message: 'Product has been created Successfully' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* editProduct({ params, callback }) {
  try {
    yield call(axiosPut, `/api/v1/products/${params.id}`, params.formData);

    Notification.success({ message: 'Product has been edited successfully.' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

export const onGetProducts = (params, setData) => ({
  type: ON_GET_PRODUCTS, params, setData,
});

export const onNewProduct = (params, callback) => ({
  type: ON_CREATE_PRODUCT, params, callback,
});

export const onEditProduct = (params, callback) => ({
  type: ON_EDIT_PRODUCT, params, callback,
});

export default function* promotionWatcher() {
  yield takeLatest(ON_GET_PRODUCTS, getProducts);
  yield takeLatest(ON_CREATE_PRODUCT, createProduct);
  yield takeLatest(ON_EDIT_PRODUCT, editProduct);
}
