import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, DatePicker,
} from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  fieldValidate, dateFormat, validateAmount
} from '../../helper';
import { onEditPromotion } from '../saga';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditPromotion(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    values.id = item.id;

    if (values.dateRange.length > 0) {
      values.start_date = values.dateRange[0].format(dateFormat);
      values.end_date = values.dateRange[1].format(dateFormat);
    }

    delete values.dateRange;
    editData(values);
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        code: item.code,
        amount: item.amount,
        promo_type: item.promoType,
        percentage: item.percentage,
        dateRange: item.startDate && item.endDate ? [moment(item.startDate, dateFormat), moment(item.endDate, dateFormat)] : [],
      });
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      title='Edit Promotion'
    >
      <Form
        layout='vertical'
        form={form}
        name='editTopic'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              name='code'
              label='Code'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Code' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='amount'
              label='Amount'
              rules={[
                fieldValidate.required,
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    return validateAmount(value, getFieldValue('promo_type'));
                  },
                }),
              ]}
            >
              <Input placeholder='Amount' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                fieldValidate.required]}
              name='promo_type'
              label='Promo Type'
            >
              <Select placeholder='Select a option'>
                <Option value='percentage'>Percentage</Option>
                <Option value='amount'>Amount</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[fieldValidate.required]}
              name='dateRange'
              label='Time:'
            >
              <RangePicker format={dateFormat} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Save
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Close
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
