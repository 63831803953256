import React from 'react';
import {
  Table, Row, Col, Typography,
} from 'antd';

const { Title } = Typography;

const CustomTable = ({
  title, columns, totalPages, currentPage, onChange, dataSource, loading, filter: FilterComponent, actionButton,
}) => (
  <Row>
    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Title level={4} style={{ marginBottom: 20 }}>{title}</Title>
    </Col>
    {FilterComponent && (
    <Col span={24} style={{ marginBottom: 10 }}>
      {FilterComponent}
    </Col>
    )}
    {actionButton && (
    <Col span={24} style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
      {actionButton}
    </Col>
    )}
    <Col span={24}>
      <Table
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        onChange={onChange}
        pagination={{
          total: (25 * totalPages),
          position: ['', 'bottomCenter'],
          pageSize: 25,
          showLessItems: true,
          current: currentPage,
          defaultCurrent: 1,
        }}
      />
    </Col>
  </Row>
);

export default CustomTable;
