import React from 'react';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import { ActionWrapper } from '../styles';
import slug from 'slug';

export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

export const StatusType = ({ status }) => {
  switch (status) {
    case 'publish':
      return <Tag color="green">Publish</Tag>;
    case 'unpublish':
      return <Tag color="red">Unpublish</Tag>;
    default:
  }
};

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {},
    defaultSortOrder: 'descend'
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: (image) => <img src={image} style={{ height: 100 }} alt="error" />
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: {}
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: {},
    render: (status) => <StatusType status={status} />
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    sorter: {},
    render: (category) => category.text
  },
  {
    title: 'Read Time',
    dataIndex: 'readTimes',
    key: 'readTimes',
    sorter: {}
  },
  {
    title: 'Published At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: {}
  },
  {
    title: 'Action',
    width: 120,
    render: (item) => (
      <ActionWrapper>
        <EyeOutlined
          style={{ cursor: 'pointer', fontSize: 17 }}
          onClick={() => {
            const url =
              item.status === 'publish'
                ? `https://www.chanceupon.co/blog/${item.id}/${slug(item.title)}`
                : `https://www.chanceupon.co/preview-blog/${item.id}/${slug(item.title)}`;
            window.open(url, '_blank');
          }}
        />
        <Tooltip title="Edit" color="geekblue" key="geekblue">
          <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onEdit(item)} />
        </Tooltip>
        <Tooltip title="Delete" color="red" key="red">
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onDelete(item)} />
        </Tooltip>
      </ActionWrapper>
    )
  }
];
