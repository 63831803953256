import {
  post, get, delete as axiosDelete, put as axiosPut,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../shared/Notification';
import { getErrorMessage } from '../../shared/const';

const ON_GET_PROMOTIONS = 'ON_GET_PROMOTIONS';
const ON_CREATE_PROMOTIONS = 'ON_CREATE_PROMOTIONS';
const ON_DELETE_PROMOTIONS = 'ON_DELETE_PROMOTIONS';
const ON_EDIT_PROMOTION = 'ON_EDIT_PROMOTION';

function* getPromotions({ params, setData }) {
  try {
    const response = yield call(get, '/api/v1/promotions', { params });
    setData({
      loading: false,
      items: response.items,
      totalPage: response.totalPage,
      currentPage: response.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* createPromotions({ params, callback }) {
  try {
    yield call(post, '/api/v1/promotions', params);

    Notification.success({ message: 'Promotion has been created Successfully' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* deletePromotions({ id, callback }) {
  try {
    yield call(axiosDelete, `/api/v1/promotions/${id}`);

    Notification.success({ message: 'Promotion has been deleted.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* editPromotion({ params, callback }) {
  try {
    yield call(axiosPut, `/api/v1/promotions/${params.id}`, params);

    Notification.success({ message: 'Promotion has been edited successfully.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

export const onGetPromotions = (params, setData) => ({
  type: ON_GET_PROMOTIONS, params, setData,
});

export const onNewPromotion = (params, callback) => ({
  type: ON_CREATE_PROMOTIONS, params, callback,
});

export const onDeletePromotion = (id, callback) => ({
  type: ON_DELETE_PROMOTIONS, id, callback,
});

export const onEditPromotion = (params, callback) => ({
  type: ON_EDIT_PROMOTION, params, callback,
});

export default function* promotionWatcher() {
  yield takeLatest(ON_GET_PROMOTIONS, getPromotions);
  yield takeLatest(ON_CREATE_PROMOTIONS, createPromotions);
  yield takeLatest(ON_DELETE_PROMOTIONS, deletePromotions);
  yield takeLatest(ON_EDIT_PROMOTION, editPromotion);
}
