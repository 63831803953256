import styled, { css } from 'styled-components';
import { Modal } from 'antd';

const headerItem = (withBackground) => css`
  background: ${withBackground ? '#fafafa' : 'transparent'};
  padding: 10px;

  .ant-form-item {
    margin: 0;
  }
`;

export const StyledModal = styled(Modal)`
  width: 1140px !important;

  @media (max-width: 1140px) {
    width: 1024px !important;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
`;

export const RowItem = styled.div`
  width: 18%;
  ${({ withBackground }) => headerItem(withBackground)}
`;

export const Name = styled.div`
  width: 45%;
  ${({ withBackground }) => headerItem(withBackground)}
`;

export const Code = styled.div`
  ${({ withBackground }) => headerItem(withBackground)}
  width: 45%;
`;

export const ColorName = styled.div`
  width: 30%;
  ${({ withBackground }) => headerItem(withBackground)}
`;

export const ColorCode = styled.div`
  ${({ withBackground }) => headerItem(withBackground)}
  width: 25%;
`;
export const ColorStatus = styled.div`
  ${({ withBackground }) => headerItem(withBackground)}
  width: 35%;
`;


export const Action = styled.div`
  ${({ withBackground }) => headerItem(withBackground)}
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 6px;
`;
