import React from 'react';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import { ActionWrapper } from '../styles';
import { numberToCurrency } from '../helper';

const statusColor = (status) => {
  switch (status) {
    case 'payment_pending':
      return 'orange';
    case 'paid':
      return 'green';
    case 'canceled':
      return 'red';
    default:
      return 'blue';
  }
};

const parcelStatusColor = (status) => {
  switch (status) {
    case 'packing':
      return 'orange';
    case 'sent':
      return 'gray';
    case 'received':
      return 'green';
    case 'refunded':
      return 'red';
    default:
      return 'blue';
  }
};

export const columns = (onUpdateStatus, onPeview) => [
  {
    title: 'Stripe Id',
    dataIndex: 'paymentId',
    key: 'payment_id',
  },
  {
    title: 'Transaction ID',
    dataIndex: 'transactionId',
    key: 'transaction_id',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (
      <Tag color={statusColor(status)}>{status.toUpperCase()}</Tag>
    ),
  },
  {
    title: 'Parcel Status',
    dataIndex: 'parcelStatus',
    key: 'parcelStatus',
    render: (parcelStatus) => (
      parcelStatus && <Tag color={parcelStatusColor(parcelStatus)}>{parcelStatus.toUpperCase()}</Tag>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => numberToCurrency(amount),
  },
  {
    title: 'Promotion Code',
    dataIndex: 'promotionCode',
    key: 'promotionCode',

  },
  {
    title: 'Order At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: {},
  },
  {
    title: 'Action',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title='Edit' color='geekblue' key='geekblue'>
          <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onUpdateStatus(item)} />
        </Tooltip>
        <Tooltip title='Preview' color='red' key='red'>
          <EyeOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onPeview(item)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
];

export default columns;
