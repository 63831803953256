import React, {
  useState, useEffect, useRef,
} from 'react';
import {
  Row, Col, Input, Typography, Button, Dropdown, Menu, Form, Skeleton, Divider, Radio,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import Ckeditor from '../../../shared/Ckeditor';

const { TextArea } = Input;
const { Title } = Typography;

const menu = (onClick) => (
  <Menu onClick={onClick}>
    <Menu.Item key='divider'>
      Divider
    </Menu.Item>
    <Menu.Item key='subtitle'>
      Subtitle
    </Menu.Item>
    <Menu.Item key='profile'>
      Profile
    </Menu.Item>
    <Menu.Item key='quote'>
      Quote
    </Menu.Item>
    <Menu.Item key='paragraph'>
      Paragraph
    </Menu.Item>
    <Menu.Item key='cta_button'>
      CTA Button
    </Menu.Item>
  </Menu>
);

const paragraphButtonStyle = {
  position: 'absolute',
  right: 0,
  top: -25,
  zIndex: 10,
};

const buttonStyle = {
  position: 'absolute',
  right: 0,
  top: 2,
  zIndex: 10,
};

const SubtitleField = ({
  position, values, removeField, content, align,
}) => {
  const onChange = (e) => {
    values.current.content[`subtitle${position}`].content = e.target.value;
  };

  const onChangeAlign = (e) => {
    values.current.content[`subtitle${position}`].align = e.target.value;
  };

  const removeItem = () => {
    delete values.current.content[`subtitle${position}`];
    removeField(position);
  };

  useEffect(() => {
    values.current.content[`subtitle${position}`] = {
      position,
      type: 'subtitle',
      content,
      align,
    };
  }, [content]);

  return (
    <Col span={24}>
      <Button type='danger' icon={<CloseOutlined />} style={buttonStyle} onClick={removeItem} size='small' />
      <Form.Item
        label='Subtitle'
      >
        <Input placeholder='Subtitle' onChange={onChange} defaultValue={content} />
        <Radio.Group onChange={onChangeAlign} defaultValue={align}>
          <Radio value='left'>Left</Radio>
          <Radio value='center'>Center</Radio>
          <Radio value='right'>Right</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>
  );
};

const QuoteField = ({
  position, values, removeField, content,
}) => {
  const onChange = (e) => {
    values.current.content[`quote${position}`] = {
      position,
      type: 'quote',
      content: e.target.value,
    };
  };

  const removeItem = () => {
    delete values.current.content[`quote${position}`];
    removeField(position);
  };

  useEffect(() => {
    values.current.content[`quote${position}`] = {
      position,
      type: 'quote',
      content,
    };
  }, [content]);

  return (
    <Col span={24}>
      <Button type='danger' icon={<CloseOutlined />} style={buttonStyle} onClick={removeItem} size='small' />
      <Form.Item
        label='Quote'
      >
        <TextArea placeholder='Quote' onChange={onChange} defaultValue={content} />
      </Form.Item>
    </Col>
  );
};

const CTAbutton = ({
  position, values, removeField, content, align, link,
}) => {
  const onChange = (e) => {
    values.current.content[`ctabutton${position}`].content = e.target.value;
  };

  const onChangeAlign = (e) => {
    values.current.content[`ctabutton${position}`].align = e.target.value;
  };

  const onChangeLink = (e) => {
    values.current.content[`ctabutton${position}`].link = e.target.value;
  };

  useEffect(() => {
    values.current.content[`ctabutton${position}`] = {
      position,
      type: 'cta_button',
      content,
      align,
      link,
    };
  }, [content]);

  const removeItem = () => {
    delete values.current.content[`ctabutton${position}`];
    removeField(position);
  };

  return (
    <Col span={24}>
      <Form.Item
        label='CTA Button'
      >
        <Button type='danger' icon={<CloseOutlined />} style={buttonStyle} onClick={removeItem} size='small' />
        <Form.Item
          label='Link'
        >
          <Input placeholder='Link' onChange={onChangeLink} defaultValue={link} />
        </Form.Item>
        <Form.Item
          label='Button Text'
        >
          <Input placeholder='Button Text' onChange={onChange} defaultValue={content} />
        </Form.Item>
        <Radio.Group onChange={onChangeAlign} defaultValue={align}>
          <Radio value='left'>Left</Radio>
          <Radio value='center'>Center</Radio>
          <Radio value='right'>Right</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>
  );
};

const ProfileContent = ({ position, values, removeField }) => {
  useEffect(() => {
    values.current.content[`profile${position}`] = {
      position,
      type: 'profile',
    };
  }, []);

  const removeItem = () => {
    delete values.current.content[`profile${position}`];
    removeField(position);
  };

  return (
    <Col span={24}>
      <Form.Item
        label='Profile'
      >
        <Button type='danger' icon={<CloseOutlined />} style={buttonStyle} onClick={removeItem} size='small' />
        <Skeleton avatar paragraph={{ rows: 1 }} />
      </Form.Item>
    </Col>
  );
};

const Paragraph = ({
  position, values, removeField, content,
}) => {
  const onChange = (value) => {
    values.current.content[`paragraph${position}`] = {
      position,
      type: 'paragraph',
      content: value,
    };
  };

  const removeItem = () => {
    delete values.current.content[`paragraph${position}`];
    removeField(position);
  };

  return (
    <Col span={24}>
      <Form.Item
        label='Paragraph'
      >
        <Button type='danger' icon={<CloseOutlined />} style={paragraphButtonStyle} onClick={removeItem} size='small' />
        <Ckeditor onChange={onChange} defaultValue={content} />
      </Form.Item>
    </Col>
  );
};

const CustomDivider = ({ position, values, removeField }) => {
  useEffect(() => {
    values.current.content[`divider${position}`] = {
      position,
      type: 'divider',
    };
  }, []);

  const removeItem = () => {
    delete values.current.content[`divider${position}`];
    removeField(position);
  };

  return (
    <Col span={24}>
      <Form.Item
        label='Divider'
      >
        <Button type='danger' icon={<CloseOutlined />} style={buttonStyle} onClick={removeItem} size='small' />
        <Divider />
      </Form.Item>
    </Col>
  );
};

const Content = ({ values, item }) => {
  const [fields, setFields] = useState([]);
  const positionRef = useRef(0);

  const selectContent = ({ key }) => {
    setFields((state) => state.concat([{
      position: positionRef.current,
      type: key,
    }]));

    positionRef.current += 1;
  };

  const removeField = (position) => {
    const newFields = fields.filter((item) => parseInt(item.position, 10) !== parseInt(position, 10));
    setFields(newFields);
  };

  useEffect(() => {
    if (item && item.content) {
      const defaultValue = Object.values(item.content);
      if (defaultValue.length > 0) positionRef.current = defaultValue[defaultValue.length - 1].position + 1;
      setFields(defaultValue);
    }
  }, [item]);

  return (
    <Row>
      <Col span={24} style={{ marginBottom: 20 }}>
        <Title level={4}>Content</Title>
        <Dropdown overlay={menu(selectContent)}>
          <Button>
            Add Content
            {' '}
            <PlusOutlined />
          </Button>
        </Dropdown>
      </Col>

      { fields.map((item) => {
        switch (item.type) {
          case 'subtitle':
            return <SubtitleField position={item.position} key={item.position} values={values} removeField={removeField} content={item.content || ''} align={item.align || 'left'} />;
          case 'quote':
            return <QuoteField position={item.position} key={item.position} values={values} removeField={removeField} content={item.content || ''} />;
          case 'profile':
            return <ProfileContent position={item.position} key={item.position} values={values} removeField={removeField} />;
          case 'cta_button':
            return <CTAbutton position={item.position} key={item.position} values={values} removeField={removeField} content={item.content || 'Button'} align={item.align || 'center'} link={item.link || '/get-started'} />;
          case 'divider':
            return <CustomDivider position={item.position} key={item.position} values={values} removeField={removeField} />;
          case 'paragraph':
            return <Paragraph position={item.position} key={item.position} values={values} removeField={removeField} content={item.content || ''} />;
          default:
            return null;
        }
      }) }

      { fields.length > 0 && (
        <Col span={24} style={{ marginBottom: 20 }}>
          <Dropdown overlay={menu(selectContent)}>
            <Button>
              Add Content
              {' '}
              <PlusOutlined />
            </Button>
          </Dropdown>
        </Col>
      ) }
    </Row>
  );
};

export default Content;
