import React, { useEffect } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  Row, Col, Typography, Input, Form, DatePicker, Select, Button,
} from 'antd';
import {
  FilterOutlined, PlusOutlined, DownloadOutlined, CloseOutlined,
} from '@ant-design/icons';
import { layout } from '../function';
import { dateFormat, country } from '../../helper';
import SearchInput from '../../../shared/SearchInput';

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;

const Filter = ({ currentFilter, changeLocation }) => {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();

  const onFilter = (values) => {
    currentFilter.current = values;

    if (values.dateRange.length > 0) {
      currentFilter.current.registerAtGteq = values.dateRange[0].format(dateFormat);
      currentFilter.current.registerAtLteq = values.dateRange[1].format(dateFormat);
    }

    delete currentFilter.current.dateRange;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};

    form.setFieldsValue({
      page: '',
      sort: '',
      categoryEq: '',
      statusEq: '',
      keyword: '',
      dateRange: [],
    });
    changeLocation();
  };

  useEffect(() => {
    const {
      categoryEq, keyword, createdAtGteq, createdAtLteq, sort, page, statusEq,
    } = queryString.parse(location.search);

    form.setFieldsValue({
      page: page || '',
      sort: sort || '',
      statusEq: statusEq || '',
      keyword: keyword || '',
      categoryEq: categoryEq || '',
      dateRange: createdAtGteq && createdAtLteq ? [moment(createdAtGteq, dateFormat), moment(createdAtLteq, dateFormat)] : [],
    });
  }, []);

  return (
    <Row>
      <Col span={24} style={{ textAlign: 'center', marginBottom: 20 }}>
        <Title level={2}>Blogs</Title>
      </Col>
      <Col span={16} offset={4}>
        <Form {...layout} form={form} onFinish={onFilter} style={{ width: '100%' }}>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name='keyword'
                    label='Search Keyword'
                  >
                    <Input placeholder='Input keyword...' />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name='dateRange'
                    label='Created Time:'
                  >
                    <RangePicker format={dateFormat} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name='statusEq'
                    label='Status'
                  >
                    <SearchInput>
                      <Option value=''>All</Option>
                      <Option value='publish'>Publish</Option>
                      <Option value='unpublish'>Unpublish</Option>
                    </SearchInput>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name='categoryEq'
                    label='Category'
                  >
                    <SearchInput placeholder='Select a option'>
                      <Option value=''>All</Option>
                      <Option value='articles'>Articles</Option>
                      <Option value='design'>Design</Option>
                      <Option value='interviews'>Interviews</Option>
                    </SearchInput>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify='center'>
            <Form.Item style={{ justifyContent: 'center', textAlign: 'center' }}>
              <Button type='primary' htmlType='submit' icon={<FilterOutlined />}>Filter</Button>
            </Form.Item>
            <Form.Item style={{ justifyContent: 'center', textAlign: 'center', marginLeft: 20 }}>
              <Button type='primary' onClick={clearFilter} icon={<CloseOutlined />}>Clear Filter</Button>
            </Form.Item>
          </Row>
        </Form>
      </Col>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Button
          type='primary'
          shape='round'
          icon={<PlusOutlined />}
          size='large'
          style={{ width: 120, marginLeft: 20 }}
          onClick={() => history.push('/blog/new')}
        >
          New
        </Button>
      </Col>
    </Row>
  );
};

export default Filter;
