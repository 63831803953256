import {
  get, put as axiosPut,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../shared/Notification';
import { getErrorMessage } from '../../shared/const';

const ON_GET_ORDERS = 'ON_GET_ORDERS';
const ON_EDIT_ORDER = 'ON_EDIT_ORDER';

function* getOrders({ params, setData }) {
  try {
    const response = yield call(get, '/api/v1/orders', { params });

    setData({
      loading: false,
      items: response.items,
      totalPage: response.totalPage,
      currentPage: response.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* editOrder({ params, callback }) {
  try {
    yield call(axiosPut, `/api/v1/orders/${params.id}`, params);

    Notification.success({ message: 'Status has been updated successfully.' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

export const onGetOrders = (params, setData) => ({
  type: ON_GET_ORDERS, params, setData,
});

export const onEditOrder = (params, callback) => ({
  type: ON_EDIT_ORDER, params, callback,
});

export default function* orderWatcher() {
  yield takeLatest(ON_GET_ORDERS, getOrders);
  yield takeLatest(ON_EDIT_ORDER, editOrder);
}
