import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import { ActionWrapper } from '../styles';

const statusColor = (status) => {
  switch (status) {
    case 'publish':
      return 'green';
    case 'unpublish':
      return 'red';
    default:
      return 'blue';
  }
};

export const columns = (onEdit) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
  },
  {
    title: 'Image',
    dataIndex: 'images',
    key: 'images',
    render: (images) => <img src={images[0] ? images[0].url : ''} style={{ height: 100 }} alt='error' />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (
      <Tag color={statusColor(status)}>{status.toUpperCase()}</Tag>
    ),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: {},
  },
  {
    title: 'Action',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title='Edit' color='geekblue' key='geekblue'>
          <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onEdit(item)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
];

export default columns;
