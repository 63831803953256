import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import { ActionWrapper } from '../styles';
import { numberToCurrency } from '../helper';

const promoTypeColor = (status) => {
  switch (status) {
    case 'percentage':
      return 'green';
    default:
      return 'blue';
  }
};

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Promo type',
    dataIndex: 'promoType',
    key: 'promoType',
    render: (promoType) => (
      <Tag color={promoTypeColor(promoType)}>{promoType.toUpperCase()}</Tag>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => numberToCurrency(amount),
  },
  {
    title: 'Start date',
    dataIndex: 'startDate',
    key: 'startDate',

  },
  {
    title: 'End date',
    dataIndex: 'endDate',
    key: 'endDate',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: {},
  },
  {
    title: 'Action',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title='Edit' color='geekblue' key='geekblue'>
          <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onEdit(item)} />
        </Tooltip>
        <Tooltip title='Delete' color='red' key='red'>
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onDelete(item)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
];

export default columns;
