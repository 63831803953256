import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Table, Row, Modal,
} from 'antd';
import { columns } from '../function';
import { onGetBlogs, onDeleteBlog } from '../saga';
import Filter from './Filter';

const Blogs = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true, items: [], currentPage: 0, totalPage: 1,
  });

  const currentFilter = useRef(queryString.parse(location.search));

  const {
    loading, items, currentPage, totalPage,
  } = state;

  const showLoading = () => setState({ ...state, ...{ loading: true } });

  const getBlogs = useCallback((params) => dispatch(
    onGetBlogs(params, setState),
  ), [dispatch]);

  const deleteBlog = useCallback((id) => dispatch(
    onDeleteBlog(id, () => {
      getBlogs(currentFilter.current);
    }),
  ), [dispatch]);

  const changeLocation = () => {
    history.push({
      pathname: '/blogs',
      search: queryString.stringify(currentFilter.current),
    });
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.field === 'readTimes'? 'read_times' : sorter.field} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  const onEdit = (item) => history.push(`/blog/edit/${item.id}`);

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Do you want delete this item?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => { deleteBlog(item.id); },
    });
  };
  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);

    showLoading();
    getBlogs(currentFilter.current);
  }, [location.search]);

  return (
    <>
      <Filter changeLocation={changeLocation} currentFilter={currentFilter} />
      <Row>
        <Table
          columns={columns(onEdit, onDelete)}
          loading={loading}
          dataSource={items}
          onChange={onChange}
          pagination={{
            total: (25 * totalPage),
            position: ['topLeft', 'bottomCenter'],
            pageSize: 25,
            showLessItems: true,
            current: currentPage,
            defaultCurrent: 1,
          }}
        />
      </Row>
    </>
  );
};

export default Blogs;
