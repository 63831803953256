import { all } from 'redux-saga/effects';
import publicRouteWatcher from '../App/PublicRoute/saga';
import privateRouteWatcher from '../App/PrivateRoute/saga';
import loginWatcher from '../pages/Login/saga';
import blogsWatcher from '../pages/Blogs/saga';
import promotionsWatcher from '../pages/Promotions/saga';
import productsWatcher from '../pages/Products/saga';
import ordersWatcher from '../pages/Orders/saga';

export default function* saga() {
  yield all([
    publicRouteWatcher(),
    privateRouteWatcher(),
    loginWatcher(),
    blogsWatcher(),
    promotionsWatcher(),
    productsWatcher(),
    ordersWatcher(),
  ]);
}
