import React, { useState, useImperativeHandle } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  ContainerOutlined, LogoutOutlined, GiftOutlined, SkinOutlined, ShoppingCartOutlined,
} from '@ant-design/icons';
import { BranchName } from '../styles';

const { Sider } = Layout;

const activeKey = {
  blogs: '/blogs',
  blog: '/blogs',
  promotions: '/promotions',
  products: '/products',
  orders: '/orders',
};

const leftNav = [
  {
    text: 'Blogs', icon: <ContainerOutlined style={{ fontSize: 20 }} />, key: '/blogs',
  },
  {
    text: 'Promotions', icon: <GiftOutlined style={{ fontSize: 20 }} />, key: '/promotions',
  },
  {
    text: 'Products', icon: <SkinOutlined style={{ fontSize: 20 }} />, key: '/products',
  },
  {
    text: 'Orders', icon: <ShoppingCartOutlined style={{ fontSize: 20 }} />, key: '/orders',
  },
];

const CustomSider = React.forwardRef(({}, ref) => {
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  useImperativeHandle(ref, () => ({
    toggle: () => setCollapsed(!collapsed),
  }));

  const onClick = ({ key }) => history.push(key);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <BranchName strong>{ collapsed ? 'CU' : 'Chanceupon'}</BranchName>
      <Menu
        theme='dark'
        mode='inline'
        defaultSelectedKeys={[activeKey[location.pathname.split('/')[1]]]}
        onClick={onClick}
      >
        {leftNav.map((item) => (
          <Menu.Item key={item.key} icon={item.icon}>
            {item.text}
          </Menu.Item>
        ))}
        <Menu.Item key='/login' icon={<LogoutOutlined />}>Logout</Menu.Item>
      </Menu>
    </Sider>
  );
});

export default CustomSider;
