import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
} from 'antd';

import { columns } from './function';
import { onGetProducts } from './saga';
import Table from '../../shared/Table';
import Filter from './Filter';
import CreateModal from './Modal/Create';
import EditModal from './Modal/Edit';

const Products = () => {
  const location = useLocation();
  const history = useHistory();
  const createRef = useRef();
  const editRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true, items: [], currentPage: 0, totalPages: 1,
  });

  const currentFilter = useRef(queryString.parse(location.search));

  const {
    loading, items, currentPage, totalPages,
  } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(
    onGetProducts(params, setState),
  ), [dispatch]);

  const changeLocation = () => {
    history.push({
      pathname: '/products',
      search: queryString.stringify(currentFilter.current),
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);

    fetchData();
  }, [location.search]);

  const onEdit = (item) => editRef.current.open(item);

  const ActionButton = () => (
    <Button
      onClick={() => createRef.current.open() }
      type='primary'
      shape='round'
      icon={<PlusOutlined />}
    >
      New
    </Button>
  );

  return (
    <>
      <CreateModal ref={createRef} fetchData={fetchData} />
      <EditModal ref={editRef} fetchData={fetchData} />
      <Table
        actionButton={<ActionButton />}
        filter={<Filter currentFilter={currentFilter} changeLocation={changeLocation} />}
        title='Products'
        columns={columns(onEdit)}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default Products;
