import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Row, Form, Modal, Button, Select,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../helper';
import { onEditOrder } from '../saga';

const { Option } = Select;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditOrder(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    editData({ id: item.id, ...values });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        status: item.status,
        parcel_status: item.parcelStatus,
      });
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      title='Update Order Status'
    >
      <Form
        layout='vertical'
        form={form}
        name='updateOrder'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              rules={[fieldValidate.required]}
              name='status'
              label='Status'
            >
              <Select placeholder='Select a option'>
                <Option value='canceled'>Canceled</Option>
                <Option value='paid'>Paid</Option>
                <Option value='payment_pending'>Payment Pending</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              rules={[fieldValidate.required]}
              name='parcel_status'
              label='Parcel Status'
            >
              <Select placeholder='Select a option'>
                <Option value='packing'>Packing</Option>
                <Option value='sent'>Sent</Option>
                <Option value='received'>Received</Option>
                <Option value='refunded'>Refunded</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Save
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Close
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
