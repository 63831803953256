import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import { columns } from './function';
import { onGetOrders } from './saga';
import Table from '../../shared/Table';
import Filter from './Filter';
import PreviewModal from './Modal/Preview';
import EditModal from './Modal/Edit';

const Orders = () => {
  const location = useLocation();
  const history = useHistory();
  const previewRef = useRef();
  const editRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true, items: [], currentPage: 0, totalPages: 1,
  });

  const currentFilter = useRef(queryString.parse(location.search));

  const {
    loading, items, currentPage, totalPages,
  } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(
    onGetOrders(params, setState),
  ), [dispatch]);

  const changeLocation = () => {
    history.push({
      pathname: '/orders',
      search: queryString.stringify(currentFilter.current),
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);

    fetchData();
  }, [location.search]);

  const onUpdateStatus = (item) => editRef.current.open(item);
  const onPreview = (item) => previewRef.current.open(item);

  return (
    <>
      <PreviewModal ref={previewRef} />
      <EditModal ref={editRef} fetchData={fetchData} />
      <Table
        filter={<Filter currentFilter={currentFilter} changeLocation={changeLocation} />}
        title='Orders'
        columns={columns(onUpdateStatus, onPreview)}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default Orders;
