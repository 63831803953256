import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import {
  Row, Col, Input, Form, Select, Button, DatePicker,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

const Filter = ({ currentFilter, changeLocation }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  let typing = null;

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.setFieldsValue({
      keyword: '',
      status: '',
      time: '',
    });
    changeLocation();
  };

  useEffect(() => {
    const {
      keyword, status, time_at_gteq, time_at_lteq,
    } = queryString.parse(location.search);

    form.setFieldsValue({
      keyword: keyword || '',
      status: status || '',
      time: time_at_gteq && time_at_lteq ? [moment(time_at_gteq, dateFormat), moment(time_at_lteq, dateFormat)] : [],
    });
  }, []);

  return (
    <Form layout='vertical' form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={6} xxl={6}>
          <Form.Item
            name='keyword'
            label='Search Keyword'
          >
            <Input placeholder='input keyword...' onChange={onSearch} />
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={6} xxl={6}>
          <Form.Item
            name='status'
            label='Promo type'
          >
            <Select
              placeholder='Select a option'
              onChange={(e) => onFilter('status', e)}
            >
              <Option value=''>All</Option>
              <Option value='paid'>Paid</Option>
              <Option value='payment_pending'>Payment Pending</Option>
              <Option value='canceled'>Canceled</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={6}>
          <Form.Item
            name='time'
            label='Time:'
          >
            <RangePicker
              format={dateFormat}
              style={{ width: '100%' }}
              placeholder={['Start Date', 'End Date']}
              onChange={(dates) => {
                if (dates) {
                  currentFilter.current.time_at_gteq = dates[0].format(dateFormat);
                  currentFilter.current.time_at_lteq = dates[1].format(dateFormat);
                } else {
                  currentFilter.current.time_at_gteq = '';
                  currentFilter.current.time_at_lteq = '';
                }

                changeLocation();
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={6}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type='primary' onClick={clearFilter} icon={<FilterOutlined />}>Clear Filter</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
